import { Fragment } from 'react';
import { Flex, Image } from 'rebass/styled-components';
import { SectionLinks } from 'react-scroll-section';
import RouteLink from './RouteLink';
import HeaderContainer from "./HeaderContainer"
import * as React from "react"

const baseUrl = "./Logo/";

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

const formatLinks = (allLinks) =>
  Object.entries(allLinks).reduce(
    (acc, [key, value]) => {
      const isHome = key === 'home';
      return isHome
        ? {
            ...acc,
            home: value,
          }
        : {
            ...acc,
            links: [...acc.links, { name: capitalize(key), value }],
          };
    },
    { links: [], home: null },
  );

const HeaderForSections = () => (
  <HeaderContainer>
    <Flex
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      p={3}
    >
      <SectionLinks>
        {({ allLinks }) => {
          const { home, links } = formatLinks(allLinks);

          const homeLink = home && (
            <Image
              src={require( `${ baseUrl + 'burritoAppsLogoWhite.png' }` )}
              width="50px"
              alt="BurritoApps Logo"
              onClick={home.onClick}
              style={{
                cursor: 'pointer',
              }}
            />
          );
          const navLinks = links.map(({ name, value }) => (
            <RouteLink
              key={name}
              onClick={value.onClick}
              selected={value.isSelected}
              name={name}
            />
          ));

          return (
            <Fragment>
              {homeLink}
              <Flex mr={[0, 3, 5]}>{navLinks}</Flex>
            </Fragment>
          );
        }}
      </SectionLinks>
    </Flex>
  </HeaderContainer>
);

export default HeaderForSections;
