import Layout from "../components/Layout"
import LandingPage from "../sections/landingPage"
import ChallengeMe from "../sections/challengeMe"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import HeaderForSections from "../components/HeaderForSections"
import * as React from "react"

const IndexPage = () => (
  <Layout>
    <SEO title="Burrito Apps" />
    <HeaderForSections />
    <div>
      <LandingPage />
      <ChallengeMe />
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
