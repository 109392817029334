
import { Flex } from "rebass/styled-components"
import Fade from "react-reveal/Fade"
import Section from "../components/Section"
import Triangle from "../components/Triangle"
import AppStoreBadge from "../assets/appStoreBadge.svg"
import PlayStoreBadge from "../images/playStoreBadge.png"
import * as React from "react"

const badgeSize = 150

const Background = () => (
  <div>
    <Triangle
      color="secondaryLight"
      height={["50vh", "20vh"]}
      width={["50vw", "50vw"]}
      invertY
    />

    <Triangle
      color="primaryDark"
      height={["20vh", "40vh"]}
      width={["75vw", "70vw"]}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={["25vh", "20vh"]}
      width={["99vw", "99vw"]}
    />
  </div>
)

const ChallengeMe = () => (
  <div>
    <Section.Container id="challengeMeApp" Background={Background}>
      <Section.Header
        name="ChallengeMeApp"
        icon="📱🌯"
        label="challengeMeApp"
      />
      <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
        <Fade bottom>
          {/*<p>Now available on AppStore and PlayStore!</p>*/}
          <div>
            {/*<Image />*/}
            <a href="https://apps.apple.com/us/app/id1511531958">
              <AppStoreBadge />
              {/*<img*/}
              {/*  alt="Download on the App Store"*/}
              {/*  src={appStoreBadge}*/}
              {/*  width={badgeWidth}*/}
              {/*/>*/}
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.burritoapps.challenge_me">
              <img
                alt="Get it on Google Play"
                src={PlayStoreBadge}
                width={badgeSize}
              />
            </a>
          </div>
        </Fade>
      </Flex>
    </Section.Container>
  </div>
)

export default ChallengeMe
