import { Fragment } from "react"
import { Heading } from "rebass/styled-components"
import Section from "../components/Section"
import Background from "../components/Background"
import { centerHorizontally } from "../styles/constants"
import * as React from "react"

const LandingPage = () => (
  <Section.Container id="home" Background={Background}>
    <Fragment>
      <div style={centerHorizontally}>
        <Heading
          textAlign="center"
          as="h1"
          color="primary"
          fontSize={[6, 7]}
          mb={[3, 4, 5]}
        >
          {`Hello, burritos!`}
        </Heading>

        <Heading
          as="h3"
          color="primary"
          fontSize={[3, 4]}
          mb={[2, 3, 4]}
          textAlign="center"
        >
          On this website, you'll find all information about burritos and apps.
        </Heading>
      </div>
    </Fragment>
  </Section.Container>
)

export default LandingPage
